/**
 * Override value, used when testing the DAMSArkeologi feature locally.
 * true - force archeology projects support on
 * false - force archeology projects support off
 * null - use DAMSArkeologi feature value from eKultur.
 * @returns {true|false|undefined}
 */

import i18n from "./app/i18n";

export const getArcheologyProjectsOverride = () => {
    try {
        return Boolean(window._env_.REACT_APP_ARCHEOLOGY_PROJECTS_OVERRIDE === 'true');
    } catch (e) {
        return undefined;
    }
};

/**
 * Returns the start of the current 24h period, based on the provided date.
 * @param d
 * @returns {Date}
 */
export const dateToStartOfDay = (d) => {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0);
};

/**
 * Returns the end of the current 24h period, based on the provided date.
 * @param d
 * @returns {Date}
 */
export const dateToEndOfDay = (d) => {
    return new Date(d.getFullYear(), d.getMonth(), d.getDate(), 23, 59, 59);
};

/**
 * Returns the browser's locale setting.
 * @param twoLettersOnly
 * @returns {string|string}
 */
export const getBrowserLocale = (twoLettersOnly = false) => {
    let selectedLanguage = i18n.language;

    // If another language have been selected by the user, override.
    const key = 'lng'
    const searchParams = window.location.search.substring(1);
    if (searchParams !== '' && searchParams.indexOf('&') > -1) {
        const args = searchParams.split('&');
        for (let i = 0, max = args.length; i < max; i++) {
            if (args[i].indexOf(key) > -1) {
                selectedLanguage = args[i].split('=')[1];
                break;
            }
        }
    } else if (searchParams !== '') {
        if (searchParams.indexOf(key) > -1) {
            selectedLanguage = searchParams.split('=')[1];
        }
    }

    if (!selectedLanguage) {
        selectedLanguage = 'no';
    }

    if (twoLettersOnly && selectedLanguage.length > 2) {
        return selectedLanguage.substring(0, 2);
    }

    return selectedLanguage;
};

/**
 * Method used to parse the data fetched from KulturNav to the format used by DAMS.
 *
 * Expected output:
 *
 *  {
 *      sourceId: hit.uuid,
 *      uniqueId: null,
 *      title: hit.caption[locale],
 *      source: hit.dataset.displayValue[locale],
 *      content: {
 *          name: hit.name[locale],
 *          yearOfBirth: hit.begin || null,
 *          yearOfDeath: hit.end || null,
 *      }
 *  }
 *
 * @param data
 * @param person
 */
export const apiCoreObjectToDAMSInput = (data, person = false) => {
    const locale = getBrowserLocale();

    const {properties: props, uuid: sourceId, name} = data;
    const fullCaption = props['entity.fullCaption'][0];
    const datasetName = props['entity.dataset'][0]['displayValue'][locale];
    const title = fullCaption['value'][locale] || fullCaption['value']['*'];
    const birthDate = (props['person.birth'] && getKulturNavPersonBirthAndDeath(props['person.birth'])) || '';
    const deathDate = (props['person.death'] && getKulturNavPersonBirthAndDeath(props['person.death'])) || '';
    const pName = name[locale] || name['*'];
    const personName = birthDate || deathDate ? `${pName} (${birthDate} - ${deathDate})` : pName;
    return {
        sourceId,
        uniqueId: null,
        source: datasetName,
        title: person ? personName : title,
        content: {
            name: personName,
            nameAndTitle: title,
            yearOfBirth: birthDate,
            yearOfDeath: deathDate
        }
    };
};

/**
 * KulturNav date-time string is returned on the following formats:
 * 1980-Y
 * 19801216-D
 *
 * In either case the first four digits are the year.
 * @param arg
 * @returns {*}
 */
const dateTimeToYearOnly = arg => {
    return arg.substring(0, 4);
};

/**
 * Returns the actual value from the specified node that holds the birth- or death date of a KulturNav person.
 * @param data
 * @returns {*|null}
 */
const getKulturNavPersonBirthAndDeath = data => {
    if (!data || data.length === 0) {
        return '';
    }

    const valueNode = data[0].value;
    const timeNode = valueNode.properties['event.time'];

    if (!timeNode || timeNode.length === 0) {
        return '';
    }

    return dateTimeToYearOnly(timeNode[0].value);
};

/**
 * Returns the specified date in a human-readable format.
 * @param d
 * @returns {string}
 */
export const getHrDateAndTime = (d) => {
    if (!d || d === '') {
        return '';
    }
    return (
        new Date(d).toLocaleDateString("nb-NO", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
        }) +
        " " +
        new Date(d).toLocaleTimeString("nb-NO")
    );
};

/**
 * Returns the name of the museum matching the model's collection ID.
 * @param museums   Array   The list of museums the current user has access to.
 * @param museumCollections Array   The list of collection IDs
 * @param collectionId
 * @returns {*}
 */
export const getMuseumNameFromCollectionId = ({museums, museumCollections, collectionId}) => {
    if (!museums) {
        throw new Error('museums not specified')
    }

    if (!museumCollections) {
        throw new Error('museumCollections not specified');
    }

    if (!collectionId) {
        throw new Error('collectionId not specified');
    }

    const museumId = museumCollections.find((mc) => {
        return mc.collectionId === collectionId;
    })?.museumId;
    return museums?.find((m) => m.id === museumId)?.name;
};

/**
 * Returns an array of museum IDs that match the specified collection IDs.
 * @param {{museums: Array, museumCollections: Array, collectionIds: Array}} options
 * @param {Array} options.museums The list of museums the current user has access to.
 * @param {Array} options.museumCollections The list of collection IDs.
 * @param {Array} options.collectionIds The collection IDs to get the museum IDs for.
 * @returns {Array} The museum IDs that match the specified collection IDs.
 */
export const getMuseumsFromCollectionIds = ({museums, museumCollections, collectionIds}) => {
    if (!collectionIds || collectionIds.length === 0) {
        throw new Error('collectionIds is not specified or has no values');
    }
    if (!museumCollections || museumCollections.length === 0) {
        throw new Error('museumCollections is not specified or has no values');
    }
    return collectionIds.map(collectionId => {
        return museumCollections.find(mc => {
            return mc.collectionId === collectionId;
        });
    });
};

/**
 * Returns the ID of the museum that matches the specified collection ID.
 * @param museums
 * @param museumCollections
 * @param collectionId
 * @returns {*}
 */
export const getMuseumIdFromCollectionId = ({museums, museumCollections, collectionId}) => {
    if (!collectionId) {
        throw new Error('collectionId not specified');
    }
    if (!museumCollections || museumCollections.length === 0) {
        throw new Error('museumCollections is not specified or has no values');
    }
    return museumCollections.find(mc => {
        return mc.collectionId === collectionId;
    })?.museumId;
};

/**
 * Returns the size of the specified file object in MB.
 * @param file  File object
 * @returns {string}
 */
export const getFileSizeInMB = (file) => {
    if (!file) {
        return 0;
    }
    return parseFloat((file.filesize / 1024 / 1024).toFixed(2));
}

/**
 * Returns the DMS ID/Media ID for the specified model.
 * @param model
 * @returns {*}
 */
export const getDmsIdFromModel = model => {
    if (!model) {
        return;
    }

    const {content} = model;
    if (content && content.mediae && content.mediae.length > 0) {
        return content.mediae[0].reference?.sourceId;
    }
};

/**
 * Returns the mime type for the specified model.
 * @param model
 * @returns {*}
 */
export const getMimeTypeFromModel = model => {
    if (!model) {
        return;
    }

    const {content} = model;
    if (content && content.mediae && content.mediae.length > 0) {
        return content.mediae[0].reference?.mimeType;
    }
};

/**
 * Formats data returned from the object's metadata to a human-readable format.
 * @param data
 * @returns {*}
 */
export const dateFormatter = data => {
    function formatDate(d) {
        if (d === '') {
            return '';
        }
        const parts = d.split(' ');
        const dateParts = parts[0].split(':');
        const dateStr = `${dateParts[2]}.${dateParts[1]}.${dateParts[0]}`;
        return `${dateStr} ${parts[1]}`;
    }


    if (!data || Object.keys(data).length === 0) {
        return '';
    }

    const dateFields = [
        'file:FileAccessDate',
        'file:FileInodeChangeDate',
        'file:FileModifyDate'
    ];

    Object.keys(data).forEach(k => {
        if (dateFields.includes(k)) {
            data[k] = formatDate(data[k]);
        }
    });
    return data;
};

/**
 * Returns a human-readable value for the selected copyright type.
 * @param t
 * @param value
 * @returns {*}
 */
export const copyrightTypeValueToHr = (t, value) => {
    if (value === 'copyright') {
        return t('copyrightTypeValueCopyright', 'Opphavsrett');
    } else if (value === 'unknown') {
        return t('copyrightTypeValueNotChosen', 'Falt i det fri');
    } else if (value === '') {
        return t('copyrightTypeValueNone', '-- Ikke valgt --');
    }
};